import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import {iconsSet as icons} from './assets/icons/icons.js'
import Vuelidate from 'vuelidate'
import Vuex from 'vuex'
import store from './store/store.js'
import VCalendar from 'v-calendar';
// import VueTheMask from 'vue-the-mask'
// Vue.use(VueTheMask)
// import FirebasePlugin from "../firebase-plugin";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
    load: {key: process.env.VUE_APP_GOOGLE_MAP_TOKEN}
});


// Use v-calendar & v-date-picker views
Vue.use(VCalendar, {
    componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
    // ...other defaults
});

Vue.use(Vuex);
Vue.use(Vuelidate);

Vue.config.performance = true;
Vue.use(CoreuiVue);

// Vue.http.headers.common['X-CSRF-TOKEN'] = document.querySelector('#token').getAttribute('content');
// Vue.use(FirebasePlugin)

new Vue({
    el: '#app',
    router,
    store,
    icons,
    template: '<App/>',
    components: {
        App
    },
});