import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'
import store from "../store/store";
import {getLocalCookies, removeLocalCookies} from "../utils/cookiesUtils";


axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL || "https://tender-telecom.pb.ua";
// axios.defaults.headers.common['X-CSRF-Token'] = "csrfToken";


// Containers
const TheContainer = () => import('../containers/TheContainer')

// Views
// const Dashboard = () => import('@/views/Dashboard')
const Tender = () => import('../views/tenders/Tender')
const TenderCreate = () => import('../views/tenders/TenderCreate')
const TenderPreview = () => import('../views/tenders/TenderPreview')
const TenderProviderCreateEditOffer = () => import('../views/tenders/TenderOffer')
const TenderDetails = () => import('../views/tenders/TenderDetails')
const TenderAllOffers = () => import('../views/tenders/TenderAllOffers')
const TendersHistory = () => import('../views/tenders/TendersHistory')
const ShowAllOffersDetails = () => import('../views/tenders/ShowAllOffersDetails')

//documents
const AttachDocument = () => import('../views/document/AttachDocument')

// Views - Pages
const Page404 = () => import('../views/page/Page404')
const Page500 = () => import('../views/page/Page500')
const Login = () => import('../views/page/Login')
const Register = () => import('../views/page/Register')
const ResetPassword = () => import('../views/page/ResetPassword')
const RecoveryPassword = () => import('../views/page/RecoveryPassword')
const FeedBack = () => import('../views/FeedBack/FeedBack')

//FAQ
const FAQ = () => import('../views/FAQ/FAQ')

// Users / Provider
const ProviderInfo = () => import('../views/users/ProviderInfo')
const ProviderOrganizationEdit = () => import('../views/users/EditProviderOrganization')
const ProviderTendersArchive = () => import('../views/history/history')
const AdminHistory = () => import('../views/history/AdminHistory')

//Admins
const AdminsPortals = () => import('../views/bank_users/AdminPortals')
const DetailsProviders = () => import('../views/bank_users/DetailsProviders')
const AdminSettings = () => import('../views/bank_users/settings/AdminSettings')

const AdminViewRules = () => import('../views/bank_users/AdminViewRules')
const AdminAppPassword = () => import('../views/bank_users/settings/AdminAppPassword')

const BankEditManagerITRP = () => import('../views/bank_users/settings/AdminEditManagerITRP');

//Dashboard
const TenderStatistics = () => import('../views/dashboard/TenderStatistics')
//Admin Email History
const AdminEmailHistory = () => import(`../views/history/AdminEmailHistory`)

const FileManager = () => import('../views/FileManager/FileManager')

Vue.use(Router);

const router = new Router({
    mode: 'history',
    linkActiveClass: 'active',
    scrollBehavior: () => ({y: 0}),
    routes: configRoutes(),

});

router.beforeEach(async function (to, from, next) {
    const authorizedCookie = getLocalCookies('tender_telecom_authorized');
    if (!store.state.authorized) store.commit('updateAuthorized', !!authorizedCookie);



    if (to.matched.some(record => record.meta.notAuthorized)) {
        store.commit('setGlobalSpinner', false);
        next();
        return;
    }

    if (!store.state.authorized) {
        store.commit('setGlobalSpinner', false);
        next({
            name: 'Login',
            query: {next: to.fullPath}
        });
    } else {
        if (!store.state.login_info.ready) {
            const loginInfoResult = await store.dispatch('GET_LOGIN_INFO');

            if (!loginInfoResult) {
                next({name: "Login"});
                return;
            }
        }

        if (to.path === '/' && store.state.authorized) next({name: 'Tenders'});
        else next();
    }


    if (store.state.login_info.bank_user && !to.path.startsWith("/v2")) {
        console.log("redirecting bank user to v2");
        window.location.href =  "/v2" + to.fullPath;
        return;
    }


    axios.interceptors.response.use(function (response) {
            return response;
        },
        function (error) {
            if (error.response.status === 401) {
                if (error.response.config.url === `/api/account/info/login`) {
                    removeLocalCookies('tender_telecom_authorized');
                    store.commit('updateAuthorized', false);
                    store.commit('clearLoginInfo');
                    next({name: "Login"});
                    return;
                }
                store.state.showModalUnauthorized = true;
                throw new axios.Cancel('user unauthorized');
            }
            return Promise.reject(error);
        });
});

function configRoutes() {
    return [
        {
            path: '*',
            name: 'Page404',
            component: Page404,
        },


        {
            path: '/tenders',
            meta: {label: 'tender'},
            component: TheContainer,
            children: [
                {
                    path: '',
                    name: 'Tenders',
                    component: Tender,
                },
                {
                    path: ':offer_id/doc',
                    name: 'TendersAttachDocument',
                    component: AttachDocument,
                },
                {
                    path: 'create',
                    name: 'TendersCreateNew',
                    component: TenderCreate,
                },
                {
                    path: ':id',
                    name: 'TendersPreview',
                    component: TenderPreview,
                },
                {
                    path: ':id/edit',
                    name: 'TendersEdit',
                    component: TenderCreate,
                },
                {
                    path: 'offer/:offers_id',
                    name: 'TendersViewOfferDetail',
                    component: TenderDetails,
                },
                {
                    path: 'offers/:id',
                    name: 'TendersViewOffers',
                    component: ShowAllOffersDetails,
                },
                {
                    path: ':order_id/offer',
                    name: 'TendersCreateOffer',
                    component: TenderProviderCreateEditOffer,
                },
                {
                    path: ':id/offers',
                    name: 'TendersViewProvidersOffer',
                    component: TenderAllOffers,
                },
                {
                    path: '/offers/:id/edit',
                    name: 'TendersOfferEdit',
                    component: TenderProviderCreateEditOffer,
                },
                {
                    path: ':order_history_id/history',
                    name: 'TendersActionHistory',
                    component: TendersHistory,
                },
            ]
        },
        {
            path: '/provider',
            meta: {label: 'Provider'},
            component: TheContainer,
            children: [
                {
                    path: '',
                    name: 'ProviderInfo',
                    component: ProviderInfo,
                },
                {
                    path: 'organization/edit',
                    meta: {label: 'ProviderOrganizationEdit'},
                    name: 'ProviderOrganizationEdit',
                    component: ProviderOrganizationEdit,
                },
                {
                    path: 'history',
                    name: 'ProviderTenderArchive',
                    component: ProviderTendersArchive,
                },
            ]
        },


        {
            path: '/faq',
            component: {
                computed: {
                    isAuthorized() {
                        return this.$store.state.authorized;
                    }
                },
                render(h) {
                    return this.isAuthorized ? h(TheContainer) : h('div', {}, [h('router-view')]);
                }
            },
            meta: {notAuthorized: true},
            children: [
                {
                    path: '',
                    name: 'FAQ',
                    component: FAQ
                }
            ]
        },
        {
            path: '/feedback',
            component: {
                computed: {
                    isAuthorized() {
                        return this.$store.state.authorized;
                    }
                },
                render(h) {
                    return this.isAuthorized ? h(TheContainer) : h('div', {}, [h('router-view')]);
                }
            },
            meta: {notAuthorized: true},
            children: [
                {path: '', component: FeedBack, name: 'FeedBack',}
            ]
        },

        {
            path: '/admin',
            component: TheContainer,
            children: [
                {path: '', redirect: {name: 'BankAdminPortal'}},
                {path: 'providers', component: AdminsPortals, name: 'BankAdminPortal'},
                {path: 'file-manager', component: FileManager, name: 'BankFileManager'},
                {path: 'dashboard', component: TenderStatistics, name: 'BankDashboard'},
                {
                    path: `history`,
                    component: {
                        render(c) {
                            return c('router-view');
                        }
                    },
                    children: [
                        {path: '', redirect: {name: 'BankHistoryEmails'}},
                        {path: 'emails', component: AdminEmailHistory, name: 'BankHistoryEmails'},
                        {path: 'orders', component: AdminHistory, name: 'BankHistoryOrders'},
                    ]
                },
                {path: 'providers/:id', component: DetailsProviders, name: 'BankDetailsProviders'},
                {
                    path: 'settings',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {path: '', component: AdminSettings, name: 'BankSettings'},
                        {path: 'app/password', component: AdminAppPassword, name: 'BankAppPassword'},
                        {path: 'app/rules', component: AdminViewRules, name: 'BankViewRules'},
                        {path: 'edit/managers-it-rp', component: BankEditManagerITRP, name: 'BankEditManagerITRP'}
                    ]
                }
            ]
        },

        {
            path: '/login',
            name: 'Login',
            component: () => import('../views/page/Login'),
            meta: {notAuthorized: true}
        },
        {
            path: '/register',
            name: 'Register',
            component: () => import('../views/page/Register'),
            meta: {notAuthorized: true}
        },
        {
            path: '/reset',
            name: 'ResetPassword',
            component: () => import('../views/page/ResetPassword'),
            meta: {notAuthorized: true}
        },
        {
            path: '/recovery',
            name: 'RecoveryPassword',
            component: () => import('../views/page/RecoveryPassword'),
            meta: {notAuthorized: true}
        },
        {
            path: '/500',
            name: 'Page500',
            component: Page500,
            meta: {notAuthorized: true}
        },
    ]
}

export default router;