import store from "../store/store";
export function can(action) {
    let rules = store.state.login_info.rules;
    let roles = store.state.login_info.roles;

    if (action === 'bank_user') return typeof roles === 'object' && roles !== null && Object.keys(roles).length;
    if (action === 'provider_user') return !!store.state.login_info.email;

    if (!rules || !Object.keys(rules).length) return false;
    if (Array.isArray(action) && Object.keys(rules).length) return action.every(act => rules.hasOwnProperty(act) && rules[act]);

    return rules[action];
}
