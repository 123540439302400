export const rules_actions = {
    create_tender: "можливість подати заявку на тендер",
    edit_tender: "Можливість редагувати тендер",
    manager_open_tender: "Можливість менеджером відкрити тендер",
    resolve_tender_remark: "Можливість редагувати тендер",
    cancel_tender_remark: "Можливість відмінити власний тендер",
    delegate_tender: "Можливість делегувати тендер",
    revoke_tender_to_editing: "Можливість відкликати тендер на редагування",
    cancel_tender: "Можливість відмінити тендер",
    choose_winner: "Можливість вибрати переможця",
    head_choose_winner: "Можливість вибрати переможця Керівником із роль - ac",
    summarize_tender: "Можливість Підвести підсумки",
    admin_continue_tender: "Адміністратор із ролю ad продовження тендера",
    manager_continue_tender: "Менеджер із ролю mg продовження тендера",
    manager_close_tender_without_winner: "Менеджер із ролю - mg, za, zm - закриває тендер без переможця",
    admin_close_tender_without_winner: "Адміністратор із ролю ad - закриває тендер без переможця",
    procurement_department_remark_tender: "Зауваження Департаментом закупівель із роль - za, zm - по тендеру",
    admin_remark_tender: "Зауваження Адміністратор із роль - ad - по тендеру",
    manager_remark_tender: "Зауваження менеджером із роль - mg - по тендеру",
    ability_make_settings: "Можливість робити налаштування сервісу із роль - mg, za, zm, ad",
    head_continue_tender: "Можливість для Керівника попросити продовжити тендер із ролю - ac",
    head_close_tender_without_winner: "Можливість керівникоом закрити тендер без переможця",
    procurement_department_choose_winner: "Можливість вибрати переможця тендеру Департаментом закупівель із роль - za, zm",
    admin_choose_winner: "Можливість вибрати переможця тендеру Адміністратором із роль - ad",
    view_page_email_history_bank_user: "Можливість переглядати сторінку відправлених Emails",
    admin_change_data_page_faq: "Можливість добавляти редагувати дані на сторінці FAQ роль - ad",
    head_change_data_page_faq: "Можливість добавляти редагувати дані на сторінці FAQ роль - ac",
    service_setting_password: "Можливість налаштовувати сервіс",
    notify_when_user_verified: 'Налаштування: сповіщення при верифікації користувача',
    notify_when_validated_applications: 'Налаштування: сповіщення при валідації заявок',
    notify_at_the_end_of_tender: 'Налаштування: сповіщення при валідації заявок',
    notify_procurement_department_about_selection_winner: 'Налаштування: департаментом закупок сповіщати про вибір переможця',
    notify_confirm_tender: 'Налаштування: Сповіщати для підтвердження тендера',
    notify_for_escalation: 'Налаштування: Сповіщати для ЕСКАЛАЦІЇ',
    notify_receiving_questions_from_provider: 'Налаштування: Сповіщати про отримання питань від провайдера',
    create_for_provider_user: 'Створення користувача для провайдера',
    change_name_organization_for_provider: 'Заміна імені організації для провайдера',
    attach_doc_failed_connect: 'Можливість вказати, що провайдер не виконав підключення',
    attach_doc_work_with_rules: 'Прикріплення документів: показуємо що можна працювати із даними',
    view_dashboard_tender_statistics: 'Можливість переглядати статистику по тендерів',
    view_edit_manager_it_rp: 'Можливість Редагування менеджерів ІТ РП'
}

export const role_actions = {
    aa: {
        "create_tender": true,
        "resolve_tender_remark": true,
        "revoke_tender_to_editing": true,
        "cancel_tender_remark": true,
        "notify_when_user_verified": true,
        "notify_when_validated_applications": true,
        "notify_confirm_tender": true,
        "notify_for_escalation": true,
        "notify_receiving_questions_from_provider": true,
        "attach_doc_work_with_rules": true,

    },
    mg: {
        "create_tender": true,
        "cancel_tender": true,
        "summarize_tender": true,
        "manager_continue_tender": true,
        "manager_open_tender": true,
        "manager_close_tender_without_winner": true,
        "manager_remark_tender": true,
        "ability_make_settings": true,
        "view_page_email_history_bank_user": true,
        "notify_when_user_verified": true,
        "notify_when_validated_applications": true,
        "notify_confirm_tender": true,
        "notify_for_escalation": true,
        "notify_receiving_questions_from_provider": true,
        "attach_doc_failed_connect": true,
        "attach_doc_work_with_rules": true,
        "procurement_department_remark_tender": true,
    },
    ad: {
        "create_tender": true,
        "edit_tender": true,
        "delegate_tender": true,
        "cancel_tender": true,
        "choose_winner": true,
        "summarize_tender": true,
        "admin_continue_tender": true,
        "manager_open_tender": true,
        "admin_close_tender_without_winner": true,
        "admin_remark_tender": true,
        "ability_make_settings": true,
        "admin_choose_winner": true,
        "view_page_email_history_bank_user": true,
        "admin_change_data_page_faq": true,
        "service_setting_password": true,
        "notify_when_user_verified": true,
        "notify_when_validated_applications": true,
        "notify_at_the_end_of_tender": true,
        "notify_confirm_tender": true,
        "notify_for_escalation": true,
        "notify_receiving_questions_from_provider": true,
        "create_for_provider_user": true,
        "change_name_organization_for_provider": true,
        "attach_doc_failed_connect": true,
        "attach_doc_work_with_rules": true,
        "view_dashboard_tender_statistics": true,
        "view_edit_manager_it_rp": true,
    },
    za: {
        "delegate_tender": true,
        "choose_winner": true,
        "manager_close_tender_without_winner": true,
        "procurement_department_remark_tender": true,
        "ability_make_settings": true,
        "procurement_department_choose_winner": true,
        "notify_procurement_department_about_selection_winner": true,
        "attach_doc_work_with_rules": true,
    },
    zm: {
        "choose_winner": true,
        "manager_close_tender_without_winner": true,
        "procurement_department_remark_tender": true,
        "ability_make_settings": true,
        "procurement_department_choose_winner": true,
        "notify_procurement_department_about_selection_winner": true,
        "attach_doc_work_with_rules": true,
    },
    vd: {},
    ac: {
        'resolve_tender_remark': true,
        'cancel_tender_remark': true,
        'head_choose_winner': true,
        'head_continue_tender': true,
        'head_close_tender_without_winner': true,
        'head_change_data_page_faq': true,
        'notify_when_validated_applications': true,
        'notify_confirm_tender': true,
        'notify_for_escalation': true,
        'notify_receiving_questions_from_provider': true,
    },

}