import Vue from "vue"
import Vuex from 'vuex'
import axios from 'axios'
import {role_actions} from "../rules/accessUtils";
import {can} from "../rules/permissions";

Vue.use(Vuex);
const store = new Vuex.Store({
    state: function () {
        return {
            organization: {
                id: 0,
                name: "",
                brand: "",
                edr: "",
                ipn: "",
                website: "",
                accounting_contacts: [],
                technical_support_contacts: [],
                accounting_emails: [],
                technical_emails: [],
                is_fop: false,
                is_pipeline: false,
                is_paper_less: false,

            },
            authorized: false,

            global_spinner: true,
            showModalUnauthorized: false,
            login_info: {
                bank_user: false,
                ready: false,
                id: 0,
                email: "",
                role: null,
                first_name: "",
                last_name: "",
                org_name: "",
                login: "",
                full_name: "",
                rules: {},
                roles: {
                    aa: false,
                    ac: false,
                    mg: false,
                    vd: false,
                    ad: false,
                    zm: false,
                    za: false,
                },
            },
            users: [
                {
                    id: 0,
                    email: "",
                    first_name: "",
                    last_name: "",
                    phones: '',
                    notification: false,
                    role: "",
                }
            ],
            regions: null,
            providers: [
                {
                    name: "",
                    brand: "",
                    edr: "",
                    ipn: "",
                    website: "",
                    is_pipeline: false,
                    is_paper_less: false,
                    is_blocked: false,
                    is_verify: false
                }
            ],
            physics: {},
            interface: {},
            protocol: {},
        }
    },
    getters: {
        // check_auth: (state) => {
        //     if (state.authorized) {
        //         return true;
        //     }
        //     state.authorized = !!localStorage.getItem('tender_telecom/authorized');
        //
        //     return state.authorized;
        // },
        organizationHasAllContacts: (state) => {
            if (state.organization.accounting_contacts.length && state.organization.accounting_emails.length &&
                state.organization.technical_emails.length && state.organization.technical_support_contacts.length) {
                return false;
            }
            return true
        },

        getPhysics: (state) => state.physics,
        getPhysicsByID: (state) => (id) => state.physics[id],
        getPhysicsLength: (state) => Object.keys(state.physics).length,

        getProtocol: (state) => state.protocol,
        getProtocolByID: (state) => (id) => state.protocol[id],
        getProtocolLength: (state) => Object.keys(state.protocol).length,

        getInterface: (state) => state.interface,
        getInterfaceByID: (state) => (id) => state.interface[id],
        getInterfaceLength: (state) => Object.keys(state.interface).length,
    },
    mutations: {
        updateRegions: (state, regions) => {
            state.regions = regions
        },
        updateProviders: (state, providers) => {
            state.providers = providers
        },
        updateLoginInfo: (state, login_info) => {
            if (!login_info.roles) {
                login_info.roles = null;
            }

            login_info.ready = true;
            login_info.bank_user = login_info.roles !== null

            state.login_info = login_info;
        },
        clearLoginInfo(state) {
            state.login_info = {
                bank_user: false,
                ready: false,
                id: 0,
                email: "",
                role: null,
                first_name: "",
                last_name: "",
                org_name: "",
                login: "",
                full_name: "",
                rules: {},
                roles: {
                    aa: false,
                    ac: false,
                    mg: false,
                    vd: false,
                    ad: false,
                    zm: false,
                    za: false,
                },
            };
        },
        updateOrganization: (state, organization) => {
            state.organization = organization;
        },
        updateUsers: (state, user) => {
            state.users = user
        },
        updateProvidersBlocked: (state, isBlocked) => {
            state.providers.is_blocked = isBlocked
        },

        updatePhysics(state, payload) {
            const dataObj = payload.reduce((obj, item) => (obj[item.id] = item.name, obj), {});
            state.physics = dataObj;
        },
        updateInterface(state, payload) {
            const dataObj = payload.reduce((obj, item) => (obj[item.id] = item.name, obj), {});
            state.interface = dataObj;
        },
        updateProtocol(state, payload) {
            const dataObj = payload.reduce((obj, item) => (obj[item.id] = item.name, obj), {});
            state.protocol = dataObj;
        },
        updateRules: (state, payload) => state.login_info.rules = payload,
        setGlobalSpinner(state, payload) {
            state.global_spinner = !!payload;
        },
        updateAuthorized(state, payload) {
            state.authorized = !!payload;
        }
    },
    actions: {
        async GET_LOGIN_INFO({commit, dispatch}) {
            return await axios.get('/api/account/info/login')
                .then((login_info) => {
                    const url = window.location.pathname;
                    if (url === '/faq' && !login_info.data.role && !login_info.data.roles) {
                        location.href = '/faq'
                        return;
                    }
                    if (login_info.status === 401) return;

                    commit('updateAuthorized', true);
                    commit('updateLoginInfo', login_info.data);
                    commit('setGlobalSpinner', false);

                    dispatch('GET_ORGANIZATION');
                    dispatch('SET_ROLE_RULES', {role_actions});
                    return login_info.data;
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        location.href = '/login';
                        return;
                    }
                    commit('updateAuthorized', false);
                    commit('clearLoginInfo');
                    return error;
                });
        },
        GET_PROVIDER({commit}) {
            return axios.get('/api/providers')
                .then((providers) => {
                    if (providers.status === 401) {
                        location.href = '/login';
                        return;
                    }
                    commit('updateProviders', providers.data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        location.href = '/login';
                        return;
                    }
                    return error;
                });
        },
        GET_REGIONS({commit}) {
            return axios('/api/account/info/regions', {method: "GET"})
                .then((regions) => {
                    if (regions.status === 401) {
                        location.href = '/login';
                        return;
                    }
                    commit('updateRegions', regions.data);
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            location.href = '/login';
                            return;
                        }
                    }
                    return error;
                })
        },
        GET_ORGANIZATION({commit, state}) {
            if (state.login_info.bank_user || state.organization.id) return;

            return axios.get('/api/account/info/organization')
                .then((organization) => {
                    if (organization.status === 401) return;
                    return organization.data;
                })
                .then(data => {
                    let organization = data;
                    let split_fields = ['accounting_contacts', 'technical_support_contacts', 'accounting_emails', 'technical_emails'];
                    split_fields.forEach(field => {
                        if (organization[field] === "") organization[field] = [];
                        else organization[field] = organization[field].split(',');
                    });
                    commit('updateOrganization', organization);
                })
                .catch((error) => {
                    return error;
                });
        },
        GET_USERS({commit}) {
            return axios.get('/api/account/info/users')
                .then((users) => {
                    if (users.status === 401) {
                        location.href = '/login';
                        return;
                    }
                    commit('updateUsers', users.data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        location.href = '/login';
                        return;
                    }
                    return error;
                });
        },

        GET_Physics({commit}) {
            return axios.get('/api/channels/physics')
                .then(resp => resp.data)
                .then(resp => commit('updatePhysics', resp))
                // .catch((err) => throw new Error(err));
                .catch((err) => console.log(err));
        },

        GET_Interface({commit}) {
            return axios.get('/api/channels/interface')
                .then(resp => resp.data)
                .then(resp => commit('updateInterface', resp))
                // .catch((err) => throw new Error(err));
                .catch((err) => console.log(err));
        },

        GET_Protocol({commit}) {
            return axios.get('/api/channels/protocol')
                .then(resp => resp.data)
                .then(resp => commit('updateProtocol', resp))
                .catch((err) => console.log(err));
        },

        async SET_ROLE_RULES({commit, state, dispatch}, {roleActions}) {
            if (!can('bank_user')) return;
            let userRules = {...state.login_info.rules};
            // let {role_actions} = roleActions;

            for (const roleRule in role_actions) {
                if (state.login_info.roles[roleRule]) {
                    const rules = role_actions[roleRule];
                    userRules = await dispatch('SET_RULES_FOR_ROLE', {userRules, rules});
                }
            }
            commit('updateRules', userRules);
        },
        async SET_RULES_FOR_ROLE({commit, state}, {userRules, rules}) {
            let updatedUserRoles = {...userRules};

            for (const ruleKey in rules) {
                if (rules[ruleKey]) {
                    updatedUserRoles[ruleKey] = true;
                }
            }
            return updatedUserRoles;
        }
    },
});
export default store
