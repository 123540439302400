export function setLocalCookies(name, value, hours = 2) {
    const currentData = new Date();
    const expirationData = new Date(currentData.getTime() + (hours * 60 * 60 * 1000));
    const expires = `expires=${expirationData.toUTCString()}`;

    const currentDomain = window.location.hostname;
    const domain = `domain=${currentDomain}`

    document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}; ${expires}; ${domain}; path=/; samesite=strict`;
}

export function getLocalCookies(name) {
    const encodeName = encodeURIComponent(name);
    const cookies = document.cookie.split('; ');

    for (const cookie of cookies) {
        const [key, value] = cookie.split('=');
        if (key === encodeName) {
            return decodeURIComponent(value);
        }

    }

    return null;
}

export function removeLocalCookies(name) {
    const expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC";
    document.cookie = `${encodeURIComponent(name)}=; ${expires}; path=/`;
}