<template>
  <div style="all: unset; margin: 0; padding: 0;">
    <template v-if="!$store.state.global_spinner">
      <router-view></router-view>
    </template>
    <template v-else>
      <loader-spinner/>
    </template>
  </div>
</template>

<script>
import LoaderSpinner from "@/containers/LoaderSpinner.vue";

export default {
  name: 'App',
  components: {LoaderSpinner},
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';
</style>
